import { useRouteData } from 'context/RouteDataProvider';
import { getExperiment } from 'services/experiments';
import { type ExistingMarkets, isExistingMarket } from 'utils/market';

const FACT_SEC_EXPERIMENT = 'FactSec';
const EXPERIMENT_MARKETS: ExistingMarkets = [
  { romania: 'ro' },
  { poland: 'pl' },
  { britain: 'en-GB' },
  { italy: 'it' },
  { france: 'fr' },
  { europe: 'en-EU' },
];
const TAMPERED_TITLE_MARKETS: ExistingMarkets = [{ britain: 'en-GB' }, { europe: 'en-EU' }];

const useFactSecExperiment = () => {
  const { market, locale } = useRouteData();

  const experimentMarket = isExistingMarket({
    market,
    markets: EXPERIMENT_MARKETS,
    locale,
  });

  const tamperedTitleMarket = isExistingMarket({
    market,
    markets: TAMPERED_TITLE_MARKETS,
    locale,
  });

  return {
    enabled: getExperiment(FACT_SEC_EXPERIMENT) === 'v1' && experimentMarket,
    showTamperedTitle: tamperedTitleMarket,
  };
};

export { useFactSecExperiment };
