import { groupBy, map } from 'lodash';
import { Tag, TagGroup, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { EXCLUDED_MARKETS } from 'constants/markets';
import { getAvailableMarkets } from 'utils/market';
import { getCountryName } from 'services/countries';
import Flag from 'components/common/Flag';
import type { Market } from 'types/market';

const COLLAPSED_MARKETS_THRESHOLD = 10;
const COLLAPSED_MARKETS_TO_SHOW = 3;
const HIDDEN_MARKETS_FLAG_COUNT = 4;
const FLAG_SIZE = 16;

const MarketList = () => {
  const { t } = useTranslation(['common', 'home']);
  const regions = groupBy(getAvailableMarkets(EXCLUDED_MARKETS), 'region');

  const renderMarkets = (markets: Market[]) => {
    const marketsLength = markets.length;
    const collapse = marketsLength >= COLLAPSED_MARKETS_THRESHOLD;
    const hiddenMarkets = markets.slice(COLLAPSED_MARKETS_TO_SHOW, marketsLength);
    const hiddenMarketsFlags = hiddenMarkets.slice(0, HIDDEN_MARKETS_FLAG_COUNT);
    const hiddenMarketsFlagsLength = hiddenMarketsFlags.length;

    return (
      <>
        {markets.map((market, index) => (
          <Tag
            className={cx(
              'cursor-default',
              collapse && index >= COLLAPSED_MARKETS_TO_SHOW && 'hidden md:block',
            )}
            key={market.countryCode}
            textValue={getCountryName(market.countryCode)}
          >
            <div className="flex gap-0.25">
              {/* @ts-expect-error TS(TS2741) */}
              <Flag size={FLAG_SIZE} countryCode={market.countryCode} rounded />

              <Text variant="xs+">{getCountryName(market.countryCode)}</Text>
            </div>
          </Tag>
        ))}

        {!!hiddenMarketsFlagsLength && (
          <Tag className="cursor-default md:hidden" textValue="Other markets">
            <div className="flex">
              {hiddenMarketsFlags.map((market, index) => (
                // @ts-expect-error TS(TS2741)
                <Flag
                  // multiplication is needed, because tailwind doesn't work with odd z-indexes
                  className={cx(
                    `relative border border-solid border-white z-[${(hiddenMarketsFlagsLength - index) * 2})]`,
                    index !== 0 && '-ml-1',
                  )}
                  key={market.countryCode}
                  size={FLAG_SIZE}
                  countryCode={market.countryCode}
                  rounded
                />
              ))}
            </div>

            <Text variant="xs+">
              +{hiddenMarkets.length} {t('home:figuresSection.markets')}
            </Text>
          </Tag>
        )}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-2 lg:gap-3">
      {map(regions, (markets, region) => (
        <div key={region} className="flex flex-col gap-1">
          <Text className="text-blue-500" as="span" variant="m+" id="marketListTitle">
            {/* @ts-expect-error TS(2345) */}
            {t(`marketSwitcher.regions.${region}`)}
          </Text>

          <TagGroup selectionMode="none" variant="white" aria-labelledby="marketListTitle">
            {renderMarkets(markets)}
          </TagGroup>
        </div>
      ))}
    </div>
  );
};

export { MarketList };
