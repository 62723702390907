import { cn } from '@carvertical/utils/styling';
import type { ParseKeys } from 'i18next';
import { useTranslation } from 'next-i18next';

type FactSecColor = 'green' | 'grey' | 'red' | 'yellow' | 'blue';

type FactSecFactBlockProps = {
  titleKey: ParseKeys<'home'>;
  count: string;
  illustration: React.ReactNode;
  color: FactSecColor;
};

const COLOR_MAP: Record<FactSecColor, string> = {
  green: 'bg-green-50',
  grey: 'bg-grey-100',
  red: 'bg-red-100',
  yellow: 'bg-yellow-100',
  blue: 'bg-blue-50',
};

const FactSecFactBlock = ({ color, titleKey, count, illustration }: FactSecFactBlockProps) => {
  const { t } = useTranslation('home');

  return (
    <article
      className={cn(
        'flex flex-row gap-3.5 rounded-xs p-2 sm:flex-col sm:gap-1.5',
        COLOR_MAP[color],
      )}
    >
      <div className="size-6 shrink-0">{illustration}</div>

      <div className="mt-auto flex flex-col gap-0.5">
        <h3 className="text-s">{t(titleKey)}</h3>

        <p className="text-h-s">{count}</p>
      </div>
    </article>
  );
};

export { FactSecFactBlock };
export type { FactSecFactBlockProps };
