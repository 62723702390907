import { toLower } from 'lodash';
import { useState } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { type Step, SwipeableSections } from 'components/landings/common/SwipeableSections';

type Illustration = {
  src: string;
  fallbackSrc: string;
};

const IMAGE_ROUTE = '/images/homeOpt/card-';
const ILLUSTRATIONS = ['mileage', 'damage', 'timeline', 'legal'];

const getStackIllustration = (locale: string): Illustration => ({
  src: `${IMAGE_ROUTE}stack-${toLower(locale)}.png`,
  fallbackSrc: `${IMAGE_ROUTE}stack-en.png`,
});

const getIllustrations = (locale: string): Illustration[] =>
  ILLUSTRATIONS.map((name) => ({
    src: `${IMAGE_ROUTE}${name}-${toLower(locale)}.png`,
    fallbackSrc: `${IMAGE_ROUTE}${name}-en.png`,
  }));

const FeaturesCards = () => {
  const { locale } = useRouteData();
  const illustrations = getIllustrations(locale);
  const stackIllustration = getStackIllustration(locale);
  const [fallback, setFallback] = useState(false);
  const stackIllustrationSrc = fallback ? stackIllustration.fallbackSrc : stackIllustration.src;

  const steps: Step[] = illustrations.map(({ src, fallbackSrc }) => {
    const illustrationSrc = fallback ? fallbackSrc : src;

    return {
      Component: (
        <div className="relative before:absolute before:left-[50%] before:top-[50%] before:h-[50%] before:w-[90%] before:translate-x-[-50%] before:translate-y-[-50%] before:rounded-l before:bg-blue-200 before:content-['']">
          <ArtDirectedImage
            className="pointer-events-none relative z-[2] object-contain"
            src={illustrationSrc}
            onError={() => setFallback(true)}
            quality={100}
            // @ts-expect-error TS(2739)
            srcSet={{
              forTabletPortraitUp: {
                src: illustrationSrc,
                height: 385,
                width: 423,
              },
            }}
            height={271}
            width={300}
          />
        </div>
      ),
    };
  });

  return (
    <>
      <ArtDirectedImage
        className="hidden lg:block"
        src={stackIllustrationSrc}
        onError={() => setFallback(true)}
        // @ts-expect-error TS(2739)
        srcSet={{
          forDesktopBigUp: {
            src: stackIllustrationSrc,
            height: 582,
            width: 592,
          },
        }}
        quality={100}
        height={550}
        width={560}
      />

      <SwipeableSections className="lg:hidden" steps={steps} />
    </>
  );
};

export { FeaturesCards };
