import { Container } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { cn } from '@carvertical/utils/styling';
import CarCheckIllustration from 'assets/illustrations/car-check.svg';
import OdometerRollbackIllustration from 'assets/illustrations/odometer-rollback.svg';
import CarDamageIllustration from 'assets/illustrations/car-damage.svg';
import CarTheftIllustration from 'assets/illustrations/car-theft.svg';
import RedesignBlogIllustration from 'assets/illustrations/redesign-blog.svg';
import { useRouteData } from 'context/RouteDataProvider';
import { formatNumber } from 'modules/shared';
import { useFactSecExperiment } from '../hooks';
import { FactSecFactBlock, type FactSecFactBlockProps } from './FactSecFactBlock';

const FactSecSection = () => {
  const { t } = useTranslation('home');
  const { locale } = useRouteData();
  const { showTamperedTitle } = useFactSecExperiment();
  const facts = getFacts(locale, showTamperedTitle);

  return (
    <section className="py-8 md:py-7">
      <Container className="flex flex-col gap-5 md:gap-7">
        <div className="flex flex-col gap-3">
          <h2 className="text-h-l">{t('factSecSection.title')}</h2>

          <p className="text-xl">{t('factSecSection.description')}</p>
        </div>

        <div
          className={cn('grid grid-cols-1 gap-3', {
            'sm:grid-cols-[repeat(auto-fill,_minmax(220px,_1fr))] lg:grid-cols-5':
              showTamperedTitle,
            'sm:grid-cols-[repeat(auto-fill,_minmax(240px,_1fr))] md:grid-cols-4':
              !showTamperedTitle,
          })}
        >
          {facts.map((fact) => (
            <FactSecFactBlock key={fact.titleKey} {...fact} />
          ))}
        </div>
      </Container>
    </section>
  );
};

function getFacts(locale: string, showTamperedTitle: boolean): FactSecFactBlockProps[] {
  const facts: FactSecFactBlockProps[] = [
    {
      color: 'green',
      titleKey: 'factSecSection.facts.vehiclesChecked',
      count: `${formatNumber(3000000, locale)}+`,
      illustration: <CarCheckIllustration original />,
    },
    {
      color: 'grey',
      titleKey: 'factSecSection.facts.mileageRollbacksExposed',
      count: formatNumber(78863, locale),
      illustration: <OdometerRollbackIllustration original />,
    },
    {
      color: 'red',
      titleKey: 'factSecSection.facts.damagedVehiclesDetected',
      count: formatNumber(1049930, locale),
      illustration: <CarDamageIllustration original />,
    },
    {
      color: 'yellow',
      titleKey: 'factSecSection.facts.stolenVehiclesIdentified',
      count: formatNumber(4142, locale),
      illustration: <CarTheftIllustration original />,
    },
  ];

  return showTamperedTitle
    ? [
        ...facts,
        {
          color: 'blue',
          titleKey: 'factSecSection.facts.tamperedTitlesExposed',
          count: formatNumber(58913, locale),
          illustration: <RedesignBlogIllustration />,
        },
      ]
    : facts;
}

export { FactSecSection };
