import { toLower } from 'lodash';
import Lottie from 'lottie-react';
import cx from 'classnames';
import { useRouteData } from 'context/RouteDataProvider';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import mascotAnimation from './assets/mascot.json';

const IMAGE_ROUTE = '/images/homeStat/stat-facts-';
const IMAGE_DESKTOP_ROUTE = `${IMAGE_ROUTE}desktop`;
const IMAGE_MOBILE_ROUTE = `${IMAGE_ROUTE}mobile`;

type Illustration = {
  src: string;
};

type StatFactsIllustrationProps = {
  desktop: Illustration;
  mobile: Illustration;
};

const getIllustration = (locale: string): StatFactsIllustrationProps => ({
  desktop: {
    src: `${IMAGE_DESKTOP_ROUTE}-${toLower(locale)}.png`,
  },
  mobile: {
    src: `${IMAGE_MOBILE_ROUTE}-${toLower(locale)}.png`,
  },
});

const StatFactsIllustration = () => {
  const { locale } = useRouteData();
  const illustration = getIllustration(locale);

  return (
    <div className="relative">
      <ArtDirectedImage
        src={illustration.mobile.src}
        className="object-contain"
        quality={100}
        // @ts-expect-error TS(2739)
        srcSet={{
          forDesktopBigUp: {
            src: illustration.desktop.src,
            height: 494,
            width: 569,
          },
          forDesktopUp: {
            src: illustration.desktop.src,
            height: 450,
            width: 520,
          },
          forTabletPortraitUp: {
            src: illustration.mobile.src,
            height: 500,
            width: 360,
          },
        }}
        height={388}
        width={280}
      />

      <Lottie
        className={cx(
          'absolute hidden lg:block',
          'lg:left-[30px] lg:top-[244px]',
          'xl:left-[36px] xl:top-[281px]',
        )}
        animationData={mascotAnimation}
      />
    </div>
  );
};

export { StatFactsIllustration };
